import { createI18n } from 'vue-i18n'
import { Device } from '@capacitor/device';

import en from './locales/en.json'
import de from './locales/de.json'
import ro from './locales/ro.json'
import ru from './locales/ru.json'

export default {
  init: async () => {
    const messages = { en, de, ro, ru }
    const fallbackLocale = 'de'

    let deviceLanguage: any = await Device.getLanguageCode();

    deviceLanguage = deviceLanguage ? deviceLanguage.value.split('-')[0] : fallbackLocale

    let defaultLocale = deviceLanguage in messages ? deviceLanguage : fallbackLocale

    // DEFAULT IS GERMAN, remove the line below when translations are implemented
    defaultLocale = 'de'

    if (! localStorage.getItem('app_locale')) localStorage.setItem('app_locale', defaultLocale);

    const locale: string = localStorage.getItem('app_locale') || defaultLocale;

    return createI18n({legacy: false,  globalInjection: true, locale, fallbackLocale, messages})
  }
}
