<template>
  <ion-page>
    <ion-header>
      <app-toolbar
        :title="$t('ClickAndLearn')"
        backButton
        :backButtonText="$t('interface.home')"
        backButtonTo="/"
        :settingsButton='false'
      />
    </ion-header>
    <ion-content style="overflow: hidden !important">
      <suspense>
        <web-view url="https://schueler.click-learn.info/" />
      </suspense>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import {
  IonPage,
  IonHeader,
  IonContent,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import AppToolbar from '@/components/app-structure/AppToolbar.vue';
import WebView from '@/components/WebView.vue'

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonContent,
    AppToolbar,
    WebView,
  }
});
</script>

