import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
import router from "@/router";

export default class OpenChatAction implements PushNotificationAction {
    protected contactId: string;
    constructor(contactId: string) {
        this.contactId = contactId;
    }

    public async run(): Promise<void> {
        const data = {
            action: 'OpenChat',
            contactId: this.contactId
        }

        if (router.currentRoute.value.name !== 'CampusApp') {
            await router.push({name: 'CampusApp', query: data});
            return
        }

        const webviewIframeEl = document.getElementById('webviewIframe') as HTMLIFrameElement;
        const contentWindow = webviewIframeEl?.contentWindow

        if (contentWindow !== null) {
            contentWindow.postMessage(data, '*')
        }
    }

}