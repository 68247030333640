import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_img, {
      src: _ctx.lightLogo,
      class: "logo light",
      style: {"width":"60%","margin":"30px auto"}
    }, null, 8, ["src"]),
    _createVNode(_component_ion_img, {
      src: _ctx.darkLogo,
      class: "logo dark",
      style: {"width":"60%","margin":"30px auto"}
    }, null, 8, ["src"])
  ], 64))
}