const availableLocales = [
    { isoCode: 'de', name: 'Deutsch', rtl: false },

    // UNCOMMENT ALL THESE LANGUAGES ONCE TRANSLATIONS ARE IMPLEMENTED
    // { isoCode: 'en', name: 'English', rtl: false },
    // { isoCode: 'gr', name: 'Ελληνικά', rtl: false },
    // { isoCode: 'es', name: 'Español', rtl: false },
    // { isoCode: 'fr', name: 'Français', rtl: false },
    // { isoCode: 'hr', name: 'Hrvatski', rtl: false },
    // { isoCode: 'it', name: 'Italiano', rtl: false },
    // { isoCode: 'pl', name: 'Polski', rtl: false },
    // { isoCode: 'ro', name: 'Română', rtl: false },
    // { isoCode: 'tr', name: 'Türkçe', rtl: false },
    // { isoCode: 'ru', name: 'Русский', rtl: false },
    // { isoCode: 'ar', name: 'اَلْعَرَبِيَّةُ', rtl: true },
]

export default availableLocales