<template>
  <ion-page>
    <ion-content style="overflow: hidden !important">
      <suspense>
        <template #default>
          <web-view url="https://test-elearning.fahrschul-campus.de/de/en-US/Home" />
        </template>
        <template #fallback>
          Wird geladen...
        </template>
      </suspense>
    </ion-content>
  </ion-page>
</template>

<style scoped lang="scss">

</style>

<script lang="js">
import {defineComponent} from "vue";
import {IonContent, IonPage} from "@ionic/vue";
import WebView from "@/components/WebView.vue";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    WebView,
  }
});
</script>
