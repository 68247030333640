<template>
    <ion-item lines="none" button router-link="/preferences/campus-central">
      <ion-label>{{ $t('Cockpit') }}</ion-label>
      <ion-icon slot="end" color="success" :ios="checkmark" :md="checkmark"></ion-icon>
    </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  IonItem,
  IonLabel,
  IonIcon
} from '@ionic/vue';
import {checkmark, close} from "ionicons/icons";

export default defineComponent({
    setup () {
      return {
        close, checkmark
      }
    },
    components: {
        IonItem,
        IonLabel,
        IonIcon
    }
})
</script>
<style lang="scss" scoped>
ion-item {
  --background: transparent !important;
}
</style>
