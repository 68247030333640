<template>
  <ion-item lines="none" :detail="false">
    <ion-label>
      <h3>{{ user.username }}</h3>
      <p>{{ user.email || $t('interface.no_email_specified') }}</p>
    </ion-label>
  </ion-item>
</template>

<script>
import {computed, defineComponent } from 'vue'
import { IonItem, IonLabel } from "@ionic/vue";
import {useStore} from "vuex";
import useHash from "@/composables/hash";

export default defineComponent({
  name: "UserInfoSettings",
  setup () {
    const store = useStore();
    const hash = useHash();
    return {
      user: computed(() => store.state.user),
      hash,
    }
  },
  components: { IonItem, IonLabel }
});
</script>

<style lang="scss" scoped>
ion-item {
  --background: tranparent !important;
}
</style>