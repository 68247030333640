import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_toolbar = _resolveComponent("app-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_campus_button = _resolveComponent("campus-button")!
  const _component_click_and_learn_button = _resolveComponent("click-and-learn-button")!
  const _component_user_info_settings = _resolveComponent("user-info-settings")!
  const _component_change_password_button = _resolveComponent("change-password-button")!
  const _component_login_button = _resolveComponent("login-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_app_toolbar, {
            title: _ctx.$t('interface.preferences'),
            settingsButton: false
          }, null, 8, ["title"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('interface.general')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_content, { style: {"padding-left":"0","padding-right":"0"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_language_selector)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.isAuthenticated && false)
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 0,
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_header, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('interface.licences')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_card_content, { style: {"padding-left":"0","padding-right":"0"} }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_campus_button),
                                  _createVNode(_component_click_and_learn_button)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('interface.account')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_content, { style: {"padding-left":"0","padding-right":"0"} }, {
                            default: _withCtx(() => [
                              (_ctx.isAuthenticated)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createVNode(_component_user_info_settings),
                                    _createVNode(_component_change_password_button)
                                  ], 64))
                                : (_openBlock(), _createBlock(_component_login_button, { key: 1 }))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}