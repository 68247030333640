<template>
  <ion-page>
    <ion-content style="overflow: hidden !important">
      <Suspense>
        <template #default>
          <web-view :url="url" :query-string="queryString" />
        </template>
        <template #fallback>
          Wird geladen...
        </template>
      </Suspense>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import {
  IonPage,
  IonContent,
} from '@ionic/vue';
import {defineComponent, onUnmounted} from 'vue';
import WebView from '@/components/WebView.vue'
import {useRoute} from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute()
    const queryString = route.query
    const url = `${process.env.VUE_APP_COCKPIT_URL}/external-login.html`

    onUnmounted(() => {
      window.history.replaceState({}, '', location.pathname);
    })

    return { queryString, url }
  },
  components: {
    IonPage,
    IonContent,
    WebView,
  }
});
</script>
