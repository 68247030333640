import PushNotificationActionCreator from "@/helpers/push/creators/PushNotificationActionCreator";
import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
import DefaultAction from "@/helpers/push/actions/DefaultAction";

export default class DefaultActionCreator extends PushNotificationActionCreator {
    protected action: string;
    constructor() {
        super();
        this.action = 'Default Action';
    }
    public create(): PushNotificationAction {
        return new DefaultAction(this.action);
    }
}
