import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_toolbar = _resolveComponent("app-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_logout_button = _resolveComponent("logout-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_app_toolbar, {
            title: _ctx.$t('interface.account'),
            backButton: "",
            backButtonText: _ctx.$t('interface.back'),
            backButtonTo: "/preferences",
            settingsButton: false
          }, null, 8, ["title", "backButtonText"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                src: `https://ui-avatars.com/api/?background=e5bb25&size=200&color=fff&name=${_ctx.user.name}`
                              }, null, 8, _hoisted_1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.user.name), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_list, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('interface.email')), 1),
                                      _createElementVNode("p", null, _toDisplayString(_ctx.user.email || 'No email specified'), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('interface.username')), 1),
                                      _createElementVNode("p", null, _toDisplayString(_ctx.user.username), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { size: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('interface.manage_account')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_content, { style: {"padding-left":"0","padding-right":"0"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_logout_button)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}