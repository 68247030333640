import GoToActionCreator from "@/helpers/push/creators/GoToActionCreator";
import DefaultActionCreator from "@/helpers/push/creators/DefaultActionCreator";
import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
import OpenCalendarActionCreator from "@/helpers/push/creators/OpenCalendarActionCreator";
import ViewAppointmentActionCreator from "@/helpers/push/creators/ViewAppointmentActionCreator";
import OpenChatActionCreator from "@/helpers/push/creators/OpenChatActionCreator";

const actions = {
  GoTo: GoToActionCreator,
  ViewCalendar: OpenCalendarActionCreator,
  OpenChat: OpenChatActionCreator,
  ViewAppointment: ViewAppointmentActionCreator,
};

export default class PushNotificationActionHandler {
  protected actionString: string;
  protected action: PushNotificationAction;
  public constructor(action: string, args: object) {
    this.actionString = `${action[0].toUpperCase()}${action.slice(1)}`;

    const creator = Object.keys(actions).includes(this.actionString)
      ? new actions[this.actionString as keyof typeof actions](args as any)
      : new DefaultActionCreator()

    this.action = creator.create();
  }

  public run(): void {
    this.action.run();
  }
}
