import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    lines: "none",
    button: "",
    "router-link": "/preferences/click-and-learn"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ClickAndLearn')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_icon, {
        slot: "end",
        color: "danger",
        ios: _ctx.close,
        md: _ctx.close
      }, null, 8, ["ios", "md"])
    ]),
    _: 1
  }))
}