import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_title = _resolveComponent("page-title")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createBlock(_component_ion_toolbar, { color: "primary" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_title, { slot: "start" }, {
        default: _withCtx(() => [
          _createVNode(_component_page_title, {
            title: _ctx.title,
            "app-icon": ""
          }, null, 8, ["title"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_buttons, { slot: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu_toggle, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    ios: _ctx.menu,
                    md: _ctx.menu
                  }, null, 8, ["ios", "md"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_buttons, { slot: "secondary" }, {
        default: _withCtx(() => [
          (_ctx.backButton)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                slot: "text-only",
                "router-link": _ctx.backButtonTo,
                "router-direction": "back"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.backButtonText), 1)
                ]),
                _: 1
              }, 8, ["router-link"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}