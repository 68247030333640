import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_loader = _resolveComponent("the-loader")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { style: {"align-items":"center","justify-content":"center"} }, {
    default: _withCtx(() => [
      _createVNode(_component_the_loader, { text: "Anmelden..." })
    ]),
    _: 1
  }))
}