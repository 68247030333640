<template>
  <ion-modal :is-open="show" @willDismiss="onWillDismiss" id="help-modal">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{$t('interface.help')}}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close" color="dark">
            <ion-icon slot="icon-only" :icon="closeIcon"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <Suspense>
      <web-view url="https://hilfe.fahrschul-campus.de" :send-open-i-d-url="false" />
    </Suspense>
  </ion-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import { close as closeIcon } from 'ionicons/icons';
import {IonButton, IonButtons, IonHeader, IonModal, IonTitle, IonToolbar, IonIcon} from "@ionic/vue";
import WebView from "@/components/WebView.vue";
import store from "@/store";

export default defineComponent({
  name: "HelpModal",
  components: {
    WebView,
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonTitle,
    IonModal,
    IonIcon
  },
  setup() {
    const show = ref(false);

    const open = () => {
      show.value = true
    }

    const close = () => {
      show.value = false
      store.commit('setHelpModalShow',false)
    }

    const onWillDismiss = () => close()

    return {
      show,
      open,
      close,
      onWillDismiss,
      closeIcon
    }
  }
})
</script>


<style scoped lang="scss">
#help-modal {
  --width: 100%;
  --height: 100%;
}
</style>
