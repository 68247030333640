import axios from "axios";
import store from './../store';

const apiVersionUri = `/api/v1`

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL + apiVersionUri,
    timeout: process.env.VUE_APP_API_TIMEOUT,
    headers: {
        "Content-Type": "application/json",
    },
    transformRequest: [
        function (data, headers) {
            const tokenResponse = localStorage.getItem('token_response')
            const accessToken = JSON.parse(tokenResponse || '')?.access_token

            headers.Authorization = 'Bearer ' + accessToken

            return JSON.stringify(data);
        },
    ],
});

api.interceptors.request.use(config => {
    const cloudApiUrl= localStorage.getItem('cloudApiUrl')
        || store.state.user.cloudApiUrl

    if (cloudApiUrl) config.baseURL = cloudApiUrl + apiVersionUri

    return config
})

api.interceptors.response.use(null, error => {
    if (!error.response) {
        console.log(error)

        error.response = {data: {message: 'Etwas ist schief gelaufen. Bitte versuche es erneut.'}}
        return Promise.reject(error)
    }

    return Promise.reject(error)
})

export default api;
