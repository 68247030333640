<template>
  <ion-card>
    <ion-card-header>
      {{ $t('interface.activate_new_license') }}
    </ion-card-header>
    <ion-item lines="none" class="ion-margin-bottom">
      <ion-input
          clear-input
          enterkeyhint="send"
          :placeholder="$t('interface.license_key')"
      />
      <ion-button slot="end" fill="clear">
        <ion-icon slot="icon-only" :ios="send" :md="send"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-card>
</template>

<script>
import {defineComponent} from "vue";
import {
  IonCard,
  IonCardHeader,
  IonItem,
  IonIcon,
  IonInput,
  IonButton
} from '@ionic/vue';
import { send } from 'ionicons/icons';

export default defineComponent({
  setup () {
    return { send }
  },
  props: {
    product: String
  },
  components: {
    IonCard,
    IonCardHeader,
    IonItem,
    IonInput,
    IonButton,
    IonIcon,
  }
});
</script>

<style scoped lang="scss">
ion-card {
  @media (prefers-color-scheme: dark) {
    --background: #363636;
  }
}
ion-item {
  --background: transparent !important;
}
</style>
