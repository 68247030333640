<template>
  <ion-page>
    <ion-header>
      <app-toolbar
        :title="$t('interface.preferences')"
        :settingsButton='false'
      />
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-card>
              <ion-card-header>{{$t('interface.general')}}</ion-card-header>
              <ion-card-content style="padding-left: 0; padding-right: 0">
                <language-selector  />
              </ion-card-content>
            </ion-card>
          </ion-col>

          <ion-col size="12" v-if="isAuthenticated && false">
            <ion-card>
              <ion-card-header>{{ $t('interface.licences') }}</ion-card-header>
              <ion-card-content style="padding-left: 0; padding-right: 0">
                <campus-button />
                <click-and-learn-button />
              </ion-card-content>
            </ion-card>
          </ion-col>

          <ion-col size="12">
            <ion-card>
              <ion-card-header>{{ $t('interface.account') }}</ion-card-header>
              <ion-card-content style="padding-left: 0; padding-right: 0">
                <template v-if="isAuthenticated" >
                  <user-info-settings />
                  <change-password-button/>
                </template>
                <login-button v-else />
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <app-footer />
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import LanguageSelector from '@/components/list-items/LanguageSelector.vue'
import {defineComponent, computed} from 'vue';
import AppToolbar from '@/components/app-structure/AppToolbar.vue';
import LoginButton from '@/components/list-items/LoginButton.vue';
import CampusButton from '@/components/list-items/CampusButton.vue'
import ClickAndLearnButton from '@/components/list-items/ClickAndLearnButton.vue'
import UserInfoSettings from '@/components/list-items/UserInfoSettings.vue'
import {useStore} from "vuex";
import ChangePasswordButton from "@/components/ChangePasswordButton.vue";
import AppFooter from "@/components/app-structure/AppFooter.vue";
export default defineComponent({
  setup () {
    const store = useStore();

    return {
      isAuthenticated: computed(() => store.state.isAuthenticated)
    }
  },
  components: {
    ChangePasswordButton,
    IonContent,
    IonHeader,
    IonPage,
    LanguageSelector,
    AppToolbar,
    LoginButton,
    CampusButton,
    ClickAndLearnButton,
    UserInfoSettings,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    AppFooter
  }
});
</script>
<style scoped lang="scss">
ion-card {
  @media (prefers-color-scheme: dark) {
    --background: #363636;
  }
}
</style>