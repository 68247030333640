<template>
    <iframe
        v-show="!iframeLoading && !isScannerRunning"
        ref="webview"
        :src="`${url}?lang=${locale}&theme=${theme}${queryStringParams}`"
        scrolling="yes"
        id="webviewIframe"
        @load="iframeLoaded"
    />

    <div class="loader">
      <the-loader v-if="iframeLoading" text=""/>
    </div>
</template>

<script lang="js">
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';

import { useI18n } from 'vue-i18n'
import { Auth } from "@/services/AuthService";
import store from "@/store";
import {useRouter} from "vue-router";
import TheLoader from "@/components/TheLoader.vue";

export default defineComponent({
  components: {TheLoader},
    async setup (props) {
      const { locale, t } = useI18n({ useScope: 'global' })
      const isScannerRunning = ref(false);
      const router = useRouter()
      const openIDUrl = process.env.VUE_APP_OPENID_URL.split('//')[1]
      const listener = async (event) => {
        const allowedOrigins = [
          'https://cockpit.fahrschul-campus.de',
          'https://test-cockpit.fahrschul-campus.de',
          'http://localhost:8081',
          'http://localhost:8080',
          'http://192.168.88.143:8081',
          'http://192.168.100.7:8080'
        ]

        if (! allowedOrigins.includes(event.origin)) return;

        const removePrefixFromKeys = (obj, prefix) => {
          const newObject = {};

          for (const key in obj) {
            if (key.startsWith(prefix)) {
              const newKey = key.slice(prefix.length);
              newObject[newKey] = obj[key];
            } else {
              newObject[key] = obj[key];
            }
          }

          return newObject;
        };

        if (event.data) {
            const originURL = new URL(event.origin);

            switch (event.data.action) {
                case 'getToken':
                    try {
                        const validityInSeconds = Auth.tokenValidForSeconds
                        const pushNotificationActionPerformed = localStorage.getItem('pushNotificationActionPerformed')
                        const tokenJustRefreshed = localStorage.getItem('tokenJustRefreshed')
                        const refreshRequired = pushNotificationActionPerformed && ! tokenJustRefreshed

                        if (pushNotificationActionPerformed) {
                            localStorage.removeItem('pushNotificationActionPerformed')
                            if (refreshRequired) {
                              await Auth.tryRefreshingTheToken()
                            }
                        } else {
                            if (! tokenJustRefreshed && validityInSeconds && validityInSeconds < 270) {
                              await Auth.tryRefreshingTheToken()
                            }
                        }

                        if (event.data.refreshRequired) {
                          await Auth.tryRefreshingTheToken()
                        }

                        event.source?.postMessage({
                          action: 'setToken',
                          tokenResponse: JSON.parse(localStorage.getItem('token_response'))
                        }, event.origin);
                    } catch (e) {
                        console.error(e)
                        Auth.clearUserData()
                        router.push({ name: 'Landing' })
                    }
                  break;
                case 'getTheoryAttendanceData' :
                    store.commit('showQRScanner', {title: t('theoryAttendance'), type: 'theory-attendance'})
                  break;
                case 'setInStorage': {
                  const eventDataItems = typeof event.data.items === 'object' ? event.data.items : {}

                  Object.keys(eventDataItems).forEach(key => {
                    localStorage.setItem(originURL.hostname + '-' + key, eventDataItems[key])
                  })
                }
                break
                case 'getFromStorage': {
                  const storageItems = {}

                  Object.keys(localStorage)
                    .filter(key => key.startsWith(originURL.hostname) && localStorage.getItem(key))
                    .forEach(key => {
                      if (!(key in storageItems)) storageItems[key] = ''
                      storageItems[key] = localStorage.getItem(key)
                    })

                  const withRemovedKeysOrigins = removePrefixFromKeys(storageItems, originURL.hostname + '-')

                  event.source?.postMessage({action: 'setInStorage', items: withRemovedKeysOrigins }, event.origin);
                }
                break
            }
        }
      }

      onMounted(() => {
        window.addEventListener("message", listener)
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', trackThemeColor);
      })

      onUnmounted(() => {
        window.removeEventListener("message", listener)
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', trackThemeColor);
      })

      const theme = ref(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

      const trackThemeColor = (e) => {
        theme.value = e.matches ? 'dark' : 'light'
      };

      let queryStringParams = ''

      if (props.queryString) {
        queryStringParams = '&' + Object.keys(props.queryString)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.queryString[key]))
            .join('&');
      }

      if (props.sendOpenIDUrl) {
        queryStringParams = `&openidurl=${openIDUrl}`
      }

      const iframeLoading = ref(true)
      const iframeLoaded = () => {
        iframeLoading.value = false
      }

      return { locale: locale.value, theme, queryStringParams, isScannerRunning, openIDUrl, iframeLoading, iframeLoaded }
    },
    props: {
      url: {
        type: String,
        required: true
      },
      sendOpenIDUrl: {
        type: Boolean,
        default: true
      },
      queryString: {
        type: Object,
        required: false
      }
    }
})
</script>

<style scoped>
iframe {
    width: 100%;
    height: 100%;
    border: none;
    margin: 0 0 -4px 0;
}
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 40%;
}
</style>
