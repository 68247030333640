<template>
  <ion-item :lines="lines ? 'full' : 'none'" button router-link="/preferences" router-direction="forward" :detail="false">
    <ion-icon v-if="icon" slot="start" :ios="person" :md="person" />
    <ion-label>
      <h3>{{ user.username }}</h3>
      <p class="email-color">{{ user.email || $t('interface.no_email_specified') }}</p>
    </ion-label>
  </ion-item>
</template>

<script>
import {computed, defineComponent } from 'vue'
import { IonItem, IonLabel, IonIcon } from "@ionic/vue";
import { person } from 'ionicons/icons';
import {useStore} from "vuex";
import useHash from "@/composables/hash";

export default defineComponent({
  name: "UserInfo",
  setup () {
    const store = useStore();
    const hash = useHash();
    return {
      user: computed(() => store.state.user),
      hash,
      person
    }
  },
  props: {
    lines: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  components: { IonItem, IonLabel, IonIcon }
});
</script>

<style lang="scss" scoped>
.email-color {
  color: black !important;
  @media (prefers-color-scheme: dark) {
    color: white !important;
  }
}
ion-item {
  @media (prefers-color-scheme: dark) {
    --background: #E5BB25 !important;
  }
  --background: #F4DF9C !important;
}
</style>