<template>
  <ion-footer>
    <ion-toolbar color="primary" class="" style="padding: 0 20px env(safe-area-inset-bottom);">
      <div slot="start">
        <a @click.prevent="openUrl('https://www.degener.de/impressum')">Impressum</a>
        &nbsp;&nbsp;
        <a @click.prevent="openUrl(privacyPolicyUrl)">Datenschutz</a>
      </div>
      <img :src="require('@/assets/png/degener-logo.png')" style="height: 20px; margin-left: auto;" alt="Degener" slot="end" />
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import {
  IonFooter,
  IonToolbar,
} from '@ionic/vue';
import { Browser } from '@capacitor/browser';
export default {
  name: "AppFooter",
  components: {
    IonFooter,
    IonToolbar
  },
  methods: {
    async openUrl(url: string) {
      await Browser.open({ url });
    }
  },
  computed: {
    privacyPolicyUrl () {
      return `${process.env.VUE_APP_COCKPIT_URL}/privacy-policy`;
    }
  }
}
</script>

<style scoped>
  a {
    color: white;
    text-decoration: none;
  }
</style>
