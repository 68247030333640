<template>
  <ion-item lines="none" button @click="handleChangePassword">
    <ion-label>{{ $t('interface.change_password') }}</ion-label>
    <ion-icon slot="end" :ios="open" :md="open" />
  </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { open } from 'ionicons/icons';
import {
  IonItem,
  IonIcon,
  IonLabel
} from '@ionic/vue';
import {Browser} from "@capacitor/browser";

export default defineComponent({
  name: "ChangePasswordButton",
  setup() {
    const openIdUrl = process.env.VUE_APP_OPENID_URL;
    const handleChangePassword = () => {
      const url = `${openIdUrl}/Manage/ChangePassword`;
      Browser.open({ url });
    }
    return { open, handleChangePassword }
  },
  components: {
      IonItem,
      IonIcon,
      IonLabel
  }
})
</script>

<style lang="scss" scoped>
ion-item {
  --background: transparent !important;
}
</style>