<template>
    <ion-toolbar color="primary">
      <ion-title slot="start">
        <page-title :title="title" app-icon />
      </ion-title>
      <ion-buttons slot="primary">
        <ion-menu-toggle>
          <ion-button>
            <ion-icon slot="icon-only" :ios="menu" :md="menu"></ion-icon>
          </ion-button>
        </ion-menu-toggle>
      </ion-buttons>
      <ion-buttons slot="secondary">
        <ion-button v-if="backButton" slot="text-only" :router-link="backButtonTo" router-direction="back">
          {{backButtonText}}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { cogOutline, menu } from 'ionicons/icons';

import {
  IonIcon,
  IonButton,
  IonToolbar,
  IonMenuToggle,
  IonTitle,
  IonButtons,
} from '@ionic/vue';
import PageTitle from "@/components/app-structure/PageTitle.vue";

export default defineComponent({
    props: {
        logo: Boolean,
        title: String,
        settingsButton: { type: Boolean, default: true },
        backButton: { type: Boolean, default: false },
        backButtonText: String,
        backButtonTo: { type: String, default: '/' }
    },
    setup() {
        return {
            cogOutline, menu
        }
    },
    components: {
        PageTitle,
        IonIcon,
        IonButton,
        IonToolbar,
        IonMenuToggle,
        IonTitle,
        IonButtons,
    }
})
</script>
