<template>
  <ion-page>
    <ion-header>
      <app-toolbar
        :title="$t('interface.licences')"
        :settingsButton='false'
      />
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-card>
              <ion-card-header>
                {{ $t('ClickAndLearn') }}
              </ion-card-header>
              <ion-item lines="none">
                <ion-label>
                  {{ $t('interface.status') }}
                </ion-label>
                <ion-icon slot="end" color="danger" :md="close" :ios="close"></ion-icon>
              </ion-item>
              <ion-item lines="none">
                <ion-label>
                  {{ $t('interface.expired') }}
                  <p>vor 3 Monaten</p>
                </ion-label>
                <ion-label slot="end">23.04.2022</ion-label>
              </ion-item>
            </ion-card>
          </ion-col>
          <ion-col>
            <licence-activation product="click-and-learn" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <app-footer />
  </ion-page>
</template>

<script>
import {defineComponent} from "vue";
import {useStore} from "vuex";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonCard,
  IonCardHeader,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon
} from '@ionic/vue';
import AppToolbar from "@/components/app-structure/AppToolbar.vue";
import LicenceActivation from "@/components/cards/LicenceActivation";
import AppFooter from "@/components/app-structure/AppFooter.vue";
import {checkmark, close} from "ionicons/icons";
export default defineComponent({
  setup () {
    const store = useStore();

    return { store, close, checkmark }
  },
  components: {
    AppFooter,
    IonContent,
    IonHeader,
    IonPage,
    IonCard,
    IonCardHeader,
    AppToolbar,
    IonItem,
    IonLabel,
    LicenceActivation,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon
  }
})
</script>

<style scoped lang="scss">
ion-card {
  @media (prefers-color-scheme: dark) {
    --background: #363636;
  }
}
ion-item {
  --background: transparent !important;
}
</style>

