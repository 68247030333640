import PushNotificationActionCreator from "@/helpers/push/creators/PushNotificationActionCreator";
import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
import OpenChatActionPayload from "@/helpers/push/dto/OpenChatActionPayload.dto";
import OpenChatAction from "@/helpers/push/actions/OpenChatAction";

export default class GoToActionCreator extends PushNotificationActionCreator {
    payload: OpenChatActionPayload;
    constructor(payload: OpenChatActionPayload) {
        super();
        this.payload = payload;
    }
    public create(): PushNotificationAction {
        return new OpenChatAction(this.payload.contactId);
    }
}