import { createApp } from 'vue'
import store from './store';
import i18n from "@/i18n";
import App from './App.vue'
import router from './router';
import GuestLayout from './layouts/GuestLayout.vue'
import MainLayout from './layouts/MainLayout.vue'
import { IonicVue } from '@ionic/vue';
import SlideUpDown from 'vue3-slide-up-down'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App);

app.use(IonicVue)
app.use(router)
app.use(store);

app.component('layout-guest', GuestLayout);
app.component('layout-main', MainLayout);
app.component('slide-up-down', SlideUpDown)

i18n.init().then(localization => {
  app.use(localization);

  router.isReady().then(() => {
    app.mount('#app');
  });
})

window.addEventListener("message", (event) => {
  const allowedOrigins = [
    'https://cockpit.fahrschul-campus.de',
    'https://test-cockpit.fahrschul-campus.de',
    'http://localhost:8081',
    'http://localhost:8080',
    'http://192.168.88.143:8081'
  ]

  if (! allowedOrigins.includes(event.origin))
    return;

  if (event.data) {
    switch (event.data.action) {
      case 'goHome' : document.getElementById('gohome')?.click(); break;
    }
  }
}, false);
