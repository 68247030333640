<template>
    <ion-item lines="none" button @click='handleSignOut()'>
        <ion-icon v-if="icon" slot="start" :ios="logOut" :md="logOut" />
        <ion-label>{{ $t('interface.logout') }}</ion-label>
    </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { logOut } from 'ionicons/icons';
import { Auth } from '@/services/AuthService';
import {
  IonItem,
  IonIcon,
  IonLabel
} from '@ionic/vue';

export default defineComponent({
  name: "LogoutButton",
  setup() {
      const handleSignOut = () => {
          Auth.Instance.signOut();
      }
      return { logOut, handleSignOut }
  },
  props: {
    icon: {
      type: Boolean,
      default: false
    }
  },
  components: {
      IonItem,
      IonIcon,
      IonLabel
  }
})
</script>

<style lang="scss" scoped>
ion-item {
  --background: transparent !important;
}
</style>