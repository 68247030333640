import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
import {Type} from "@/helpers/push/types/AppointmentsTypes.enum";
import router from "@/router";

export default class ViewAppointmentAction implements PushNotificationAction {
    type: Type;
    id: number;
    educationClass: string;

    constructor(type: Type, id: number, educationClass: string) {
        this.type = type;
        this.id = id;
        this.educationClass = educationClass;
    }

    public async run(): Promise<void> {
        const data = {
            action: 'ViewAppointment',
            type: this.type,
            educationClass: this.educationClass,
            id: this.id
        }

        if (router.currentRoute.value.name !== 'CampusApp') {
            await router.push({name: 'CampusApp', query: data});
            return
        }

        const webviewIframeEl = document.getElementById('webviewIframe') as HTMLIFrameElement;

        if (webviewIframeEl && webviewIframeEl.contentWindow) {
            webviewIframeEl.contentWindow.postMessage(data, '*')
        }
    }
}