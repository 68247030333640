import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
import router from "@/router";

export default class OpenCalendarAction implements PushNotificationAction {
    protected date: Date;
    constructor(date: Date) {
        this.date = date;
    }
    public async run(): Promise<void> {
        const data = {
            action: 'ViewCalendar',
            date: this.date?.toDateString()
        }

        if (router.currentRoute.value.name !== 'CampusApp') {
           await router.push({name: 'CampusApp', query: data});
        }

        const webviewIframeEl = document.getElementById('webviewIframe') as HTMLIFrameElement;
        const contentWindow = webviewIframeEl.contentWindow

        if (contentWindow !== null) {
            contentWindow.postMessage(data, '*')
        }
    }
}