<template>
  <ion-page style="align-items: center; justify-content: center">
   <the-loader text="Anmelden..."/>
  </ion-page>
</template>

<script lang="ts">
import {Auth} from '@/services/AuthService';
import {IonPage} from '@ionic/vue';
import {AuthActions} from 'ionic-appauth';
import {Subscription} from 'rxjs';
import {defineComponent} from 'vue';
import TheLoader from "@/components/TheLoader.vue";

export default defineComponent({
  name: 'StartRedirect',
  data() {
    return {
      sub: Subscription.EMPTY
    }
  },
  created() {
    this.sub = Auth.Instance.events$.subscribe((action) => {
      if (action.action === AuthActions.SignInSuccess) {
        setTimeout(() => this.$router.push('/home'), 100)
      }

      if (action.action === AuthActions.SignInFailed) {
        setTimeout(() => this.$router.push('/landing'), 100)
      }
    });

    const url = this.$route.fullPath;
    Auth.Instance.authorizationCallback(url);
  },
  beforeUnmount() {
    this.sub.unsubscribe();
  },
  components: {
    TheLoader,
    IonPage,
  }
});
</script>
