import { App } from '@capacitor/app';
import { AuthService } from 'ionic-appauth';
import { CapacitorBrowser } from 'ionic-appauth/lib/capacitor';
import { isPlatform } from '@ionic/vue';
import { AxiosRequestor } from './AxiosService';
import {LocalStorageBackend} from "@openid/appauth";
import store from "@/store";

export class Auth  {
  private static authService: AuthService | undefined;
  private static redirectUrl: string = isPlatform('capacitor') ? 'de.degener.campus://callback' : window.location.origin + '/authcallback';
  private static endSessionUrl: string = isPlatform('capacitor') ?  'de.degener.campus://endSession' : window.location.origin + '/endsession';
  private static clientId = process.env.VUE_APP_API_CLIENT_ID;
  private static baseUrl = process.env.VUE_APP_OPENID_URL;
  private static buildAuthInstance() {
    const authService = new AuthService(new CapacitorBrowser(), new LocalStorageBackend(), new AxiosRequestor());

    authService.authConfig = {
      client_id: Auth.clientId,
      server_host: Auth.baseUrl,
      redirect_url: Auth.redirectUrl,
      end_session_redirect_url: Auth.endSessionUrl,
      scopes: 'profile email Campus.Fahrschueler Campus.SignalR roles offline_access openid Fso.Elbe.Api Fso.Cloud.Api cloudapi',
      pkce: true
    }

    if (isPlatform('capacitor')) {
      App.addListener('appUrlOpen', (data: any) => {
        if ((data.url).indexOf(authService.authConfig.redirect_url) === 0) {
          authService.authorizationCallback(data.url);
        } else {
          authService.endSessionCallback();
        }
      });
    }

    authService.init();
    return authService;
  }

  public static get Instance(): AuthService {
    if (!this.authService) {
      this.authService = this.buildAuthInstance();
    }
    return this.authService;
  }

  public static tryRefreshingTheToken() {
    localStorage.setItem('tokenJustRefreshed', JSON.stringify(true))

    return new Promise((resolve, reject) => {
      const tokenResponse = localStorage.getItem('token_response')
      if (! tokenResponse) {
        reject('No token stored in the storage. Authentication required.')
      }

      const { refresh_token } = JSON.parse(tokenResponse || '')
      if (! refresh_token) {
        reject('No refresh token was found. Authentication required.')
      }

      const url = `${Auth.baseUrl}/connect/token`
      const params = {
        client_id: Auth.clientId,
        redirect_uri: Auth.redirectUrl,
        grant_type: 'refresh_token',
        refresh_token
      }

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(params).toString()
      })
        .then(response => {
          localStorage.removeItem('tokenJustRefreshed')

          if (!response.ok) {
            Auth.clearUserData()
            document.getElementById('gohome')?.click();

            return reject(response.json())
          }

          return response.json()
        })
        .then(async data => {
          let fullData = { ... data }

          if (data.refresh_token && data.access_token) {
            const issued_at = Math.floor(new Date().getTime() / 1000);

            fullData = { ... data, issued_at }

            localStorage.setItem('token_response', JSON.stringify(fullData))

            setTimeout(() => localStorage.removeItem('tokenJustRefreshed'), 30 * 1000)
          }

          await Auth.Instance.loadTokenFromStorage()

          resolve(fullData)
        })
        .catch(error => {
          console.error(JSON.stringify(error))
          localStorage.removeItem('tokenJustRefreshed')
          reject(error)
        });
    })
  }

  public static get tokenValidForSeconds(): number {
    const tokenResponse = localStorage.getItem('token_response')

    if (! tokenResponse) return 0

    const { expires_in, issued_at } = JSON.parse(tokenResponse || '')
    const current_time = Math.floor(new Date().getTime() / 1000);
    const elapsed_time = current_time - issued_at;

    return expires_in - elapsed_time;
  }

  public static clearUserData() {
    localStorage.removeItem('fcmToken')
    localStorage.removeItem('fcmTokenSaved')
    localStorage.removeItem('cloudApiUrl')
    localStorage.removeItem('tokenJustRefreshed')
    localStorage.removeItem('token_response')
    localStorage.removeItem('pushNotificationActionPerformed')

    store.commit('setUserInfo', {
      name: null,
      username: null,
      email: null,
      cloudApiUrl: null,
    })
    store.commit('setSchoolLoadingStatus',true)
    store.commit('setSchoolInfo', {
      city: '',
      email: '',
      facebook: '',
      instagram: '',
      logo: '',
      name: '',
      phone: '',
      snapChat: '',
      street: '',
      tikTok: '',
      website: '',
      youtube: '',
      zipCode: ''
    })
  }
}
