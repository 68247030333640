import router from "@/router";
import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
export default class GoToAction implements PushNotificationAction {
    protected routeName: string;
    constructor(routeName: string) {
        this.routeName = routeName;
    }
    public run(): void {
        router.push({ name: this.routeName });
    }
}