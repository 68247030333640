<template>
  <ion-img :src="lightLogo" class="logo light" style="width: 60%; margin: 30px auto"/>
  <ion-img :src="darkLogo"  class="logo dark" style="width: 60%; margin: 30px auto"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { IonImg } from "@ionic/vue";

export default defineComponent({
  name: 'AppLogo',
  components: { IonImg },
  computed: {
    lightLogo () {
      return require('@/assets/png/logo-light.png')
    },
    darkLogo () {
      return require('@/assets/png/logo-dark.png')
    },
  }
});
</script>

<style scoped lang="scss">
  .logo {
    display: none;
  }
  @media (prefers-color-scheme: dark) {
    .logo.dark {
      display: block;
    }
  }
  @media (prefers-color-scheme: light) {
    .logo.light {
      display: block;
    }
  }
</style>