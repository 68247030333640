<template>
    <ion-item button router-link="preferences" lines="none">
        <ion-icon slot="start" :ios="cog" :md="settings" />
        <ion-label>{{ $t('interface.preferences') }}</ion-label>
    </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { settings, cog } from 'ionicons/icons';
import {
  IonItem,
  IonIcon,
  IonLabel
} from '@ionic/vue';

export default defineComponent({
  name: "SettingsButton",
  setup() {
      return { settings, cog }
  },
  components: {
      IonItem,
      IonIcon,
      IonLabel
  }
})
</script>

<style lang="scss" scoped>
ion-item {
  --background: transparent !important;
}
</style>