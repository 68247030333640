import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    lines: "none",
    button: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSignOut()))
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 0,
            slot: "start",
            ios: _ctx.logOut,
            md: _ctx.logOut
          }, null, 8, ["ios", "md"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('interface.logout')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}