<template>
  <span style="display: none">Init push notifications...</span>
</template>
<script>
import FcmService from "@/services/FcmService";
const fcmService = new FcmService();
import {defineComponent, onUnmounted} from "vue";

export default defineComponent({
  async setup() {
    onUnmounted(() => {
      fcmService.removeListeners();
    })

    await fcmService.initPush()
  }
});
</script>
