import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
import GoToAction from "@/helpers/push/actions/GoToAction";
import PushNotificationActionCreator from "@/helpers/push/creators/PushNotificationActionCreator";
import GoToActionPayload from "@/helpers/push/dto/GoToActionPayload.dto";

export default class GoToActionCreator extends PushNotificationActionCreator {
    protected routeName: string;
    constructor(payload: GoToActionPayload) {
        super();
        this.routeName = payload.routeName;
    }
    public create(): PushNotificationAction {
        return new GoToAction(this.routeName);
    }
}