<template>
  <ion-page>
    <ion-header>
      <app-toolbar
          :title="$t('interface.account')"
          backButton
          :backButtonText="$t('interface.back')"
          backButtonTo="/preferences"
          :settingsButton='false'
      />
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-card>
              <ion-card-header>
                <img :src="`https://ui-avatars.com/api/?background=e5bb25&size=200&color=fff&name=${user.name}`">
              </ion-card-header>
              <ion-card-content>
                <ion-card-title>{{ user.name }}</ion-card-title>
              </ion-card-content>
              <ion-list lines="none" >
                <ion-item>
                  <ion-label>
                    <h3>{{ $t('interface.email') }}</h3>
                    <p>{{ user.email || 'No email specified' }}</p>
                  </ion-label>
                </ion-item>
                <ion-item>
                  <ion-label>
                    <h3>{{ $t('interface.username') }}</h3>
                    <p>{{ user.username }}</p>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card>
          </ion-col>
          <ion-col size="12">
            <ion-card>
              <ion-card-header>
                {{ $t('interface.manage_account') }}
              </ion-card-header>
              <ion-card-content style="padding-left: 0; padding-right: 0">

                <logout-button/>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import AppToolbar from "@/components/app-structure/AppToolbar.vue";
import {defineComponent, computed} from 'vue';
import {useStore} from "vuex";
import useHash from "@/composables/hash";
import { openOutline as open } from 'ionicons/icons';
import LogoutButton from '@/components/list-items/LogoutButton.vue';


export default defineComponent({
  setup () {
    const store = useStore();
    const user = computed(() => store.state.user);
    const hash = useHash();
    return { user, hash, open }
  },
  components: {
    AppToolbar,
    IonContent,
    IonHeader,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonList,
    IonItem,
    IonLabel,
    LogoutButton,
    IonGrid,
    IonRow,
    IonCol
  }
});
</script>

<style scoped>

</style>
