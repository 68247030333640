import PushNotificationActionCreator from "@/helpers/push/creators/PushNotificationActionCreator";
import OpenCalendarActionPayload from "@/helpers/push/dto/OpenCalendarActionPayload.dto";
import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";
import OpenCalendarAction from "@/helpers/push/actions/OpenCalendarAction";

export default class OpenCalendarActionCreator extends PushNotificationActionCreator {
    payload: OpenCalendarActionPayload;
    constructor(payload: OpenCalendarActionPayload) {
        super();
        this.payload = payload;
    }
    public create(): PushNotificationAction {
        return new OpenCalendarAction(this.payload.date);
    }
}