import PushNotificationActionCreator from "@/helpers/push/creators/PushNotificationActionCreator";
import ViewAppointmentAction from "@/helpers/push/actions/ViewAppointmentAction";
import ViewAppointmentActionPayload from "@/helpers/push/dto/ViewAppointmentActionPayload.dto";
import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";

export default class ViewAppointmentActionCreator extends PushNotificationActionCreator {
    payload: ViewAppointmentActionPayload;
    constructor(payload: ViewAppointmentActionPayload) {
        super();
        this.payload = payload;
    }

    public create(): PushNotificationAction {
        return new ViewAppointmentAction(this.payload.type, this.payload.id, this.payload.educationClass);
    }
}