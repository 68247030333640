<template>
    <ion-item button router-link="/" router-direction="back">
        <ion-icon slot="start" :ios="apps" :md="apps" />
        <ion-label>Apps</ion-label>
    </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { apps } from 'ionicons/icons';
import {
  IonItem,
  IonIcon,
  IonLabel
} from '@ionic/vue';

export default defineComponent({
  name: "AppsButton",
  setup() {
      return { apps }
  },
  components: {
      IonItem,
      IonIcon,
      IonLabel
  }
})
</script>

<style lang="scss" scoped>
ion-item {
  --background: transparent !important;
}
</style>