import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"inline-flex","align-items":"center","align-self":"center","width":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_img, {
      "router-link": "/",
      "router-direction": "root",
      src: _ctx.iconImage,
      style: {"height":"32px","width":"32px","margin-right":"10px"}
    }, null, 8, ["src"]),
    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
  ]))
}