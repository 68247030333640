import PushNotificationAction from "@/helpers/push/interfaces/PushNotificationAction";

export default class DefaultAction implements PushNotificationAction {
    protected action: string;
    constructor(action: string) {
        this.action = action;
    }
    public run(): void {
        console.log(`Default action: ${this.action}`);
    }
}