<template>
    <ion-item lines="none">
        <ion-label>{{$t('interface.language')}}</ion-label>
        <ion-icon v-if="icon" slot="start" :ios="language" :md="language" />
        <ion-select interface="action-sheet" :value="selectedLanguage" v-model="$i18n.locale" @ionChange="changeLanguage">
          <ion-select-option
            v-for="locale in availableLocales"
            :value="locale.isoCode"
            :key="locale.isoCode"
          >
            {{ locale.name }}
          </ion-select-option>
        </ion-select>
    </ion-item>
</template>

<script>
import { defineComponent } from 'vue'
import { language } from 'ionicons/icons';
import availableLocales from "@/locales";
import {
  IonItem,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonLabel
} from '@ionic/vue';

export default defineComponent({
    setup() {
        const selectedLanguage = localStorage.getItem('app_locale') || 'de';
        const changeLanguage = ev => {
            localStorage.setItem('app_locale', ev.detail.value)
        }

        return { language, availableLocales, selectedLanguage, changeLanguage }
    },
    props: {
      icon: {
        type: Boolean,
        default: false
      }
    },
    components: {
      IonItem,
      IonSelect,
      IonSelectOption,
      IonIcon,
      IonLabel
    }
})
</script>
<style lang="scss" scoped>
ion-item {
  --background: transparent !important;
}
</style>
