<template>
    <ion-item lines="none" button router-link="/preferences/click-and-learn">
        <ion-label>{{ $t('ClickAndLearn') }}</ion-label>
        <ion-icon slot="end" color="danger" :ios="close" :md="close"></ion-icon>
    </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  IonItem,
  IonLabel,
  IonIcon
} from '@ionic/vue';
import { close, checkmark } from 'ionicons/icons';

export default defineComponent({
  setup () {
    return {
      close, checkmark
    }
  },
  components: {
      IonItem,
      IonLabel,
      IonIcon
  }
})
</script>
<style lang="scss" scoped>
ion-item {
  --background: transparent !important;
}
</style>