import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2259ea0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "start" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createBlock(_component_ion_footer, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, {
        color: "primary",
        class: "",
        style: {"padding":"0 20px env(safe-area-inset-bottom)"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($options.openUrl('https://www.degener.de/impressum')), ["prevent"]))
            }, "Impressum"),
            _createTextVNode("    "),
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($options.openUrl($options.privacyPolicyUrl)), ["prevent"]))
            }, "Datenschutz")
          ]),
          _createElementVNode("img", {
            src: require('@/assets/png/degener-logo.png'),
            style: {"height":"20px","margin-left":"auto"},
            alt: "Degener",
            slot: "end"
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}